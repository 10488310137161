import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ContactForm from 'components/contact-form';
import Container from 'components/container';
import Heading from 'components/heading';
import Hr from 'components/hr';
import RichText from 'components/rich-text';
import { useSettings } from 'hooks';

const Career = ({ data }) => {
  const {
    wpCareer: { title, content },
  } = data;

  const {
    contactForm: {
      contactForm: {
        action,
        confirmationMessage,
        errorMessage,
        formId,
        heading,
      },
    },
  } = useSettings();

  return (
    <>
      <Hr />
      <Container className='py-8 lg:grid grid-cols-12 gap-gutter'>
        <article className='col-span-5'>
          <Heading as='h1' className='mb-3' html={title} size='xl' />
          <RichText html={content} />
        </article>
        <div />
        <section className='col-span-5'>
          <ContactForm
            isJobApplication
            action={action}
            className='-lg:mt-8 lg:sticky lg:top-10'
            confirmationMessage={confirmationMessage}
            errorMessage={errorMessage}
            formId={formId}
            heading={heading}
            jobPosition={title}
          />
        </section>
        <div />
      </Container>
    </>
  );
};

Career.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Career;

export const query = graphql`
  query CareerById($id: String) {
    wpCareer(id: { eq: $id }) {
      title
      content
      ...SeoCareer
    }
  }
`;
